import { users } from "../Mocks/Users";

export async function login(payload) {
  //   const url = new URL("api/auth/local", process.env.REACT_APP_URL_API);
  //   try {
  //     const response = await axios.post(url, payload);

  //     if (response) {
  //       localStorage.setItem("user", JSON.stringify(response.data));
  //     }

  //     return response.data;
  //   } catch (error) {
  //     if (error.response && error.response.status === 401) {
  //       // Authentication failed, handle the error as needed
  //       console.error("Invalid username or password");
  //     } else {
  //       // Handle other errors
  //       console.error("An error occurred during login:", error.message);
  //     }

  //     throw error; // Re-throw the error to propagate it further if needed
  //   }

  //FAKE LOGIN
  try {
    const { identifier, password } = payload;

    const user = users.find((user) => user.identifier === identifier);

    if (user && user.password === password) {
      localStorage.setItem("user", JSON.stringify(user));
      window.location.reload();
    } else {
      console.error("Invalid username or password");
      throw {
        code: "ERR_BAD_REQUEST",
        message: "Invalid username or password",
      };
    }
  } catch (error) {
    console.error("An error occurred during login:", error.message);
    throw error;
  }
}

export const logout = () => {
  localStorage.removeItem("user");
  window.location.reload();
};

export const getCurrentUser = () => {
  const userStr = localStorage.getItem("user");
  if (userStr) return JSON.parse(userStr);
  return null;
};
