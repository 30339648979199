import React from "react";

function Button({ className, label, onClick }) {
  return (
    <button type="button" onClick={() => onClick()} className={className}>
      {label}
    </button>
  );
}

export default Button;
