import React, { useState } from "react";
import { login, getCurrentUser } from "../Services/authService";
import { Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Input from "../Components/Input";
import Button from "../Components/Button";

import Graphic from '../Components/Graphic';

function Login() {

  const { t, i18n } = useTranslation();
  const intialValues = { identifier: "", password: "" };
  const [formValues, setFormValues] = useState(intialValues);
  const [error, setError] = useState();

  const currentUser = getCurrentUser();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      await login(formValues);
    } catch (error) {
      setError(error?.code);
    }
  };

  return !currentUser ? (
    <section className="login">
      <i className="illo"><Graphic name="main"/></i>
      <div className="content form">
        <Input
          type="text"
          placeholder={t("login.identifier")}
          iconName="iconEmail"
          id="identifier"
          name="identifier"
          onChange={handleChange}
          required
        />
        <Input
          type="password"
          placeholder={t("login.password")}
          id="password"
          name="password"
          onChange={handleChange}
          required
        />
        {error ? <span className="error">{t(`login.errors.${error}`)}</span> : null}
        <Button label={t("login.submit")} onClick={handleSubmit} />
      </div>
      <ul className="languages">
        <li>
          <a
            className={i18n.resolvedLanguage === "fr" ? "disabled" : null}
            href="#"
            onClick={() => changeLanguage("fr")}
          >
            Français
          </a>
        </li>
        <li>
          <a
            className={i18n.resolvedLanguage === "en" ? "disabled" : null}
            href="#"
            onClick={() => changeLanguage("en")}
          >
            English
          </a>
        </li>
        <li>
          <a
            className={i18n.resolvedLanguage === "md" ? "disabled" : null}
            href="#"
            onClick={() => changeLanguage("md")}
          >
            中文
          </a>
        </li>
      </ul>
    </section>
  ) : (
    <Navigate to="/" />
  );
}

export default Login;
