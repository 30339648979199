import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
  Navigate
} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import Home from './Pages/Home';
import Details from './Pages/Details';
import Contact from './Pages/Contact';
import Navbar from './Components/Navbar';

import './i18n';

import "./Assets/style/index.scss";
import Login from './Pages/Login';
import { getCurrentUser } from './Services/authService';

const isAuthenticated = getCurrentUser();

const PrivateRoute = ({ element, isPrivate }) => {
  

  return isPrivate ? (isAuthenticated ? element : <Navigate to="/login" />) : element;
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <PrivateRoute element={<Home />} isPrivate={true} />,
  },
  // {
  //   path: '/details',
  //   element: <PrivateRoute element={<Details />} isPrivate={true} />,
  // },
  {
    path: '/contact',
    element: <PrivateRoute element={<Contact />} isPrivate={true} />,
  },
  {
    path: "/login",
    element: <Login />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {isAuthenticated ? <Navbar /> : null}
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
