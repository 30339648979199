import React from "react";
import { useTranslation } from "react-i18next";
import { logout, getCurrentUser } from "../Services/authService";

import Graphic from "../Components/Graphic";
import Button from "./Button";

function Navbar() {
  const { t, i18n } = useTranslation();
  const currentUser = getCurrentUser();

  console.log(i18n.resolvedLanguage);
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  return (
    <nav>
      <a href="/">
        <Graphic name="logo" />
      </a>
      <ul className="languages">
        <li>
          <a
            className={i18n.resolvedLanguage === "fr" ? "disabled" : null}
            href="#"
            onClick={() => changeLanguage("fr")}
          >
            Français
          </a>
        </li>
        <li>
          <a
            className={i18n.resolvedLanguage === "en" ? "disabled" : null}
            href="#"
            onClick={() => changeLanguage("en")}
          >
            English
          </a>
        </li>
        <li>
          <a
            className={i18n.resolvedLanguage === "md" ? "disabled" : null}
            href="#"
            onClick={() => changeLanguage("md")}
          >
            中文
          </a>
        </li>
      </ul>
      <div className="actions">
        <a href="/contact" className="cta done">
          {t("nav.rsvp")}
          <Graphic name="iconCompleted"/>
        </a>
        {currentUser ? (
          <button className="secondary" type="button" onClick={() => logout()}>
            {t("nav.logout")}
          </button>
        ) : null}
      </div>
    </nav>
  );
}

export default Navbar;
