import React from "react";

function Checkbox({ id, label, value, onChange }) {
  return (
    <div>
      <input id={value} type="checkbox" onChange={() => onChange(id, value)} />
      <label htmlFor={value}>{label}</label>
    </div>
  );
}

export default Checkbox;
