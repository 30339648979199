import React from 'react';
import { useTranslation } from 'react-i18next';
import Card from '../Components/Card';
import Graphic from '../Components/Graphic';

import cardMeet from '../Assets/images/cazalet.jpg';
import cardSign from '../Assets/images/sign.jpg';
import cardMap from '../Assets/images/map.png';
import cardVenue from '../Assets/images/domaine-beaufort.jpg';
import cardCeremony from '../Assets/images/ceremony.jpg';
import cardRelax from '../Assets/images/relax.jpg';
import cardWine from '../Assets/images/wine.jpg';
import cardFood from '../Assets/images/repas.jpg';
import cardParty from '../Assets/images/party.jpg';
import cardBrunch from '../Assets/images/brunch.jpg';

function Home () {
    const { t } = useTranslation();

    return (
        <>
        <section className="hero">
            <div className="content">
                <i className="illo"><Graphic name="main"/></i>
                <header>
                    <h4>{t("home.hero.subtitle")}</h4>
                    <h1>{t("home.hero.title")}</h1>
                </header>
                <p>{t("home.hero.contentTop")}</p>
                <div className="infoBlock">
                    <span>
                        <Graphic name="iconCalendar"/>
                        <h4>{t("home.hero.info.whentitle")}</h4>
                        <p>{t("home.hero.info.whencontent")}</p>
                    </span>
                    <span>
                        <Graphic name="iconLocation"/>
                        <h4>{t("home.hero.info.locationtitle")}</h4>
                        <p>{t("home.hero.info.locationcontent")}</p>
                    </span>
                    <span>
                        <Graphic name="iconParty"/>
                        <h4>{t("home.hero.info.partytitle")}</h4>
                        <p>{t("home.hero.info.partycontent")}</p>
                    </span>
                    <a href="/contact" className="cta done">
                        {t("home.hero.cta")}
                        <Graphic name="iconCompleted"/>
                    </a>
                </div>
                <p>{t("home.hero.contentBottom")}</p>
                <br/>
                <p><em>{t("home.hero.contentBottomExtra")}</em></p>
            </div>
        </section>
        <section className="schedule">
            <div className="content">
                <h1>{t("home.schedule.title")}</h1>
                <Card
                    image={cardMeet}
                    title={t("home.schedule.cardMeet.title")}
                    time={t("home.schedule.cardMeet.time")}
                    location={t("home.schedule.cardMeet.location")}
                    content={t("home.schedule.cardMeet.content")}
                />
                <Card
                    image={cardSign}
                    title={t("home.schedule.cardSign.title")}
                    time={t("home.schedule.cardSign.time")}
                    location={t("home.schedule.cardSign.location")}
                    content={t("home.schedule.cardSign.content")}
                />
                <Card
                    image={cardMap}
                    title={t("home.schedule.cardBreak.title")}
                    time={t("home.schedule.cardBreak.time")}
                    content={t("home.schedule.cardBreak.content")}
                />
                <Card
                    image={cardVenue}
                    title={t("home.schedule.cardVenue.title")}
                    time={t("home.schedule.cardVenue.time")}
                    location={t("home.schedule.cardVenue.location")}
                    content={t("home.schedule.cardVenue.content")}
                />
                <Card
                    image={cardCeremony}
                    title={t("home.schedule.cardCeremony.title")}
                    time={t("home.schedule.cardCeremony.time")}
                    location={t("home.schedule.cardCeremony.location")}
                    content={t("home.schedule.cardCeremony.content")}
                />
                <Card
                    image={cardRelax}
                    title={t("home.schedule.cardRelax.title")}
                    time={t("home.schedule.cardRelax.time")}
                    location={t("home.schedule.cardRelax.location")}
                    content={t("home.schedule.cardRelax.content")}
                />
                <Card
                    image={cardWine}
                    title={t("home.schedule.cardWine.title")}
                    time={t("home.schedule.cardWine.time")}
                    location={t("home.schedule.cardWine.location")}
                    content={t("home.schedule.cardWine.content")}
                />
                <Card
                    image={cardFood}
                    title={t("home.schedule.cardFood.title")}
                    time={t("home.schedule.cardFood.time")}
                    location={t("home.schedule.cardFood.location")}
                    content={t("home.schedule.cardFood.content")}
                />
                <Card
                    image={cardParty}
                    title={t("home.schedule.cardParty.title")}
                    time={t("home.schedule.cardParty.time")}
                    location={t("home.schedule.cardParty.location")}
                    content={t("home.schedule.cardParty.content")}
                />
                <h2>{t("home.schedule.nextDay.title")}</h2>
                <Card
                    image={cardBrunch}
                    title={t("home.schedule.cardBrunch.title")}
                    time={t("home.schedule.cardBrunch.time")}
                    location={t("home.schedule.cardBrunch.location")}
                    content={t("home.schedule.cardBrunch.content")}
                />
                <Card
                    title={t("home.schedule.cardEnd.title")}
                    time={t("home.schedule.cardEnd.time")}
                    location={t("home.schedule.cardEnd.location")}
                    content={t("home.schedule.cardEnd.content")}
                />
            </div>
        </section>
        <section className="venue">
            <div className="content">
                <h4>{t("home.venue.subtitle")}</h4>
                <h1>{t("home.venue.title")}</h1>
                <p>{t("home.venue.contentTop")}</p>
                <p>{t("home.venue.contentBottom")}</p>
                <a href="https://www.domainedebeaufort.com" target="_blank">{t("home.venue.web")}</a>
            </div>
        </section>
        <section className="coming">
            <div className="content">
                <h1>{t("home.coming.title")}</h1>
                <h2>{t("home.coming.townhall.title")}</h2>
                <div className="infoBlock">
                    <span>
                        <Graphic name="iconCar"/>
                        <h4>{t("home.coming.townhall.carTitle")}</h4>
                        <p>{t("home.coming.townhall.carContent")}</p>
                    </span>
                    <span>
                        <Graphic name="iconPlane"/>
                        <h4>{t("home.coming.townhall.planeTitle")}</h4>
                        <p>{t("home.coming.townhall.planeContent")}</p>
                    </span>
                    <span>
                        <Graphic name="iconTrain"/>
                        <h4>{t("home.coming.townhall.trainTitle")}</h4>
                        <p>{t("home.coming.townhall.trainContent")}</p>
                    </span>
                </div>
                <h2>{t("home.coming.venue.title")}</h2>
                <div className="infoBlock">
                    <span>
                        <Graphic name="iconCar"/>
                        <h4>{t("home.coming.venue.carTitle")}</h4>
                        <p>{t("home.coming.venue.carContent")}</p>
                    </span>
                    <span>
                        <Graphic name="iconTrain"/>
                        <h4>{t("home.coming.venue.trainTitle")}</h4>
                        <p>{t("home.coming.venue.trainContent")}</p>
                    </span>
                </div>
            </div>
        </section>
        <section className="stay">
            <div className="content">
                <h1>{t("home.stay.title")}</h1>
                <div className="infoBlock">
                    <span>
                        <p>{t("home.stay.content")}</p>
                    </span>
                    <span>
                        <ul>
                            <li>
                                <a href="https://maps.app.goo.gl/61RSVdu62Pdk9FqUA" target="_blank">Beauronne</a>
                            </li>
                            <li>
                                <a href="https://maps.app.goo.gl/GKHgnpjZQB26wURAA" target="_blank">Manoir de La Baronie</a>
                            </li>
                            <li>
                                <a href="https://maps.app.goo.gl/HicnByoZz2DDNnit5" target="_blank">Château la Thuilière</a>
                            </li>
                            <li>
                                <a href="https://maps.app.goo.gl/EbKb5Bp1vho31HkK6" target="_blank">Hôtes de L'Isle</a>
                            </li>
                            <li>
                                <a href="https://maps.app.goo.gl/H7y9qXjSzAkGrT8E6" target="_blank">Les Cèdres Bleus</a>
                            </li>
                        </ul>
                    </span>
                </div>
            </div>
        </section>
        </>
    )
}

export default Home;