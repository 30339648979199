import React from "react";
import Graphic from "./Graphic";

function Card({ title, time, location, content, image }) {

  return (
    <article className="tile">
      {image &&
        <figure>
          <img alt="" src={image}/>
        </figure>
      }
      <figcaption>
        <header>
          <h2>{title}</h2>
          <div className="infos">
            <span>
              <Graphic name="iconTime"/>
              {time}
            </span>
            {location &&
              <span>
              <Graphic name="iconLocation"/>
              {location}
            </span>
            }
          </div>
        </header>
        <p>{content}</p>
      </figcaption>
    </article>
  );
}

export default Card;
