import React from 'react';

import svg from '../Assets/js/sprite.js';


export default class Graphic extends React.Component {
    render() {
        return svg[this.props.name];
    }
}
