import React from 'react';
import { useTranslation } from 'react-i18next';
import Form from '../Components/Form';

import Graphic from '../Components/Graphic';


function Contact () {
    const { t } = useTranslation();

    return (
        <>
        <section className="hero">
            <div className="content">
                <i className="illo"><Graphic name="main"/></i>
                <header>
                    <h4>{t("home.hero.subtitle")}</h4>
                    <h1>{t("form.hero.title")}</h1>
                </header>
                <p>{t("form.hero.contentTop")}</p>
            </div>
        </section>
        <section>
            <div className="content">
                <Form />
            </div>
        </section>
        </>
    )
}

export default Contact;