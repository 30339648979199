import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { getCurrentUser } from "../Services/authService";
import Checkbox from "./Checkbox";

import Input from "./Input";

const url =
  "https://jonathanjato.us12.list-manage.com/subscribe/post?u=8e900acdd9d5c865c54636838&amp;id=11fd6c2208&amp;f_id=006846e0f0";

function Form() {
  const { t } = useTranslation();
  const user = getCurrentUser();
  const intialValues = {
    FNAME: user?.firstname,
    LNAME: user?.lastname,
    EMAIL: user?.identifier,
    PHONE: "",
    "group[54111]": "",
    CHILDREN: 0,
    CAR: 0,
    "group[54115]": "",
    MESSAGE: "",
  };

  const requiredFields = {
    FNAME: true,
    LNAME: true,
    EMAIL: true,
    PHONE: true,
    "group[54111]": true, // array
    CHILDREN: false,
    CAR: false,
    "group[54115]": true,
    MESSAGE: false,
  };
  const [formValues, setFormValues] = useState(intialValues);
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};

    Object.keys(requiredFields).forEach((fieldName) => {
      const value = formValues[fieldName];

      if (
        requiredFields[fieldName] &&
        (Array.isArray(value) ? value.length === 0 : !value)
      ) {
        newErrors[fieldName] = fieldName;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    setErrors({});
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };


  const handleCheck = (id, value) => {
    setErrors({});

    setFormValues((prevState) => {
      // Créer une copie de l'état actuel
      const updatedFormValues = { ...prevState };
  
      // Vérifier si la clé existe déjà dans le formulaire
      if (updatedFormValues.hasOwnProperty(value)) {
        // Si elle existe, la supprimer
        delete updatedFormValues[value];
      } else {
        // Sinon, l'ajouter avec la valeur true
        updatedFormValues[value] = true;
      }
  
      // Retourner le nouvel état mis à jour
      return updatedFormValues;
    });
  };

  const handleChangeRadio = (id, value) => {
    setErrors({});
    setFormValues({
      ...formValues,
      [id]: value,
    });
  };

  const handleSubmit = (subscribe) => async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      await subscribe(formValues);
      console.log("RSVP successful!");
    } catch (error) {
      console.error("Error submitting RSVP:", error);
    }
  };

  return (
    <MailchimpSubscribe
      url={url}
      render={({ subscribe, status, message }) => (
        <>
          {status === "sending" || status === "error" || !status ? (
            <form
              method="post"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              className="rsvp"
              target="_self"
              noValidate
              onSubmit={handleSubmit(subscribe)}
            >
              <div className="grid">
                <Input
                  type="text"
                  placeholder={t("form.firstname")}
                  id="FNAME"
                  name="FNAME"
                  value={formValues.FNAME}
                  error={errors.FNAME ? t(`form.error.${errors.FNAME}`) : null}
                  onChange={handleChange}
                  required
                />
                <Input
                  type="text"
                  placeholder={t("form.lastname")}
                  value={formValues.LNAME}
                  id="LNAME"
                  name="LNAME"
                  error={errors.LNAME ? t(`form.error.${errors.LNAME}`) : null}
                  onChange={handleChange}
                  required
                />
              </div>
              <Input
                type="text"
                placeholder={t("form.email")}
                id="EMAIL"
                name="EMAIL"
                error={errors.EMAIL ? t(`form.error.${errors.EMAIL}`) : null}
                value={formValues.EMAIL}
                onChange={handleChange}
                disabled
              />
              <Input
                type="tel"
                placeholder={t("form.phone")}
                id="PHONE"
                name="PHONE"
                error={errors.PHONE ? t(`form.error.${errors.PHONE}`) : null}
                onChange={handleChange}
                required
              />
              <div>
                <b>{t("form.come_to.label")}</b>
                {errors["group[54103]"] ? (
                  <span className="error">
                    {t(`form.error.${errors["group[54103]"]}`)}
                  </span>
                ) : null}
                <Checkbox
                  id={"group[54103]"}
                  value={"group[54103][1]"}
                  onChange={handleCheck}
                  label={t("form.come_to.townhall")}
                />
                <Checkbox
                  id={"group[54103]"}
                  value={"group[54103][2]"}
                  onChange={handleCheck}
                  label={t("form.come_to.ceremony")}
                />
                <Checkbox
                  id={"group[54103]"}
                  value={"group[54103][4]"}
                  onChange={handleCheck}
                  label={t("form.come_to.nothing")}
                />
              </div>
              <div>
                <b>{t("form.diet.label")}</b>
                {errors["group[54107]"] ? (
                  <span className="error">
                    {t(`form.error.${errors["group[54107]"]}`)}
                  </span>
                ) : null}

                <Checkbox
                  id={"group[54107]"}
                  value={"group[54107][8]"}
                  onChange={handleCheck}
                  label={t("form.diet.meat")}
                />
                <Checkbox
                  id={"group[54107]"}
                  value={"group[54107][16]"}
                  onChange={handleCheck}
                  label={t("form.diet.fish")}
                />
                <Checkbox
                  id={"group[54107]"}
                  value={"group[54107][32]"}
                  onChange={handleCheck}
                  label={t("form.diet.vegetarian")}
                />
                <Checkbox
                  id={"group[54107]"}
                  value={"group[54107][64]"}
                  onChange={handleCheck}
                  label={t("form.diet.vegan")}
                />
                <Checkbox
                  id={"group[54107]"}
                  value={"group[54107][128]"}
                  onChange={handleCheck}
                  label={t("form.diet.gluten_free")}
                />
                <Checkbox
                  id={"group[54107]"}
                  value={"group[54107][256]"}
                  onChange={handleCheck}
                  label={t("form.diet.other")}
                />
              </div>
              <div>
                <b>{t("form.need_ride.title")}</b>
                <p>{t("form.need_ride.info")}</p>
                {errors["group[54111]"] ? (
                  <span className="error">
                    {t(`form.error.${errors["group[54111]"]}`)}
                  </span>
                ) : null}
                <div>
                  <input
                    type="radio"
                    name="group[54111]"
                    id="need_ride-yes"
                    onClick={() => handleChangeRadio("group[54111]", "512")}
                  />
                  <label htmlFor="need_ride-yes">
                    {t("form.need_ride.yes")}
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    name="group[54111]"
                    id="need_ride-no"
                    onClick={() => handleChangeRadio("group[54111]", "1024")}
                  />
                  <label htmlFor="need_ride-no">{t("form.need_ride.no")}</label>
                </div>
              </div>
              <div>
                <label>{t("form.car_share")}</label>
                <select name="CAR" onChange={handleChange}>
                  <option value={0}>0</option>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                </select>
              </div>
              <div>
                <label>{t("form.child")}</label>
                <select name="CHILDREN" onChange={handleChange}>
                  <option value={0}>0</option>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                </select>
              </div>
              <div>
                <b>{t("form.sleep.title")}</b>
                {errors["group[54115]"] ? (
                  <span className="error">
                    {t(`form.error.${errors["group[54115]"]}`)}
                  </span>
                ) : null}
                <div>
                  <input
                    type="radio"
                    name="group[54115]"
                    id="need_sleep-yes"
                    onClick={() => handleChangeRadio("group[54115]", "2048")}
                  />
                  <label htmlFor="need_sleep-yes">{t("form.sleep.yes")}</label>
                </div>
                <div>
                  <input
                    type="radio"
                    name="group[54115]"
                    id="need_sleep-no"
                    onClick={() => handleChangeRadio("group[54115]", "4096")}
                  />
                  <label htmlFor="need_sleep-no">{t("form.sleep.no")}</label>
                </div>
              </div>
              <div className="textarea">
                <label>{t("form.message")}</label>
                <textarea name="MESSAGE" onChange={handleChange}></textarea>
              </div>
              <div className="actions">
                <a href="/" className="cta secondary">
                  {t("form.nav")}
                </a>
                <button type="submit" onClick={() => handleSubmit(subscribe)}>
                  {t("form.button")}
                </button>
              </div>
              {status === "error" && (
                <div
                  className="error"
                  dangerouslySetInnerHTML={{ __html: message }}
                />
              )}
            </form>
          ) : null}
          {status === "sending" ? (
            <div className="sending">{t("form.sending")}</div>
          ) : null}

          {status === "success" ? (
            <div className="success">{t("form.success")}</div>
          ) : null}
        </>
      )}
    />
  );
}

export default Form;
