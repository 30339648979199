import React from "react";
import Graphic from "./Graphic";

function Input({
  type,
  id,
  label,
  placeholder,
  error,
  iconName,
  required,
  value,
  disabled,
  onChange,
}) {
  const [typeState, setType] = React.useState("password");

  const handleChangeType = () => {
    setType(typeState === "password" ? "text" : "password");
  };
  return type !== "password" ? (
    <div>
      {label && <label htmlFor="password">{label}</label>}
      <input
        className={iconName ? "icon" : error ? "error" : null}
        type={type}
        value={value}
        placeholder={placeholder}
        id={id}
        name={id}
        onChange={onChange}
        required={required}
        disabled={disabled}
      />
      {iconName ? <Graphic name={iconName} /> : null}
      {error ? <span className="error">{error}</span> : null}
    </div>
  ) : (
    <div>
      {label && <label htmlFor="password">{label}</label>}
      <input
        onChange={onChange}
        className="icon"
        type={typeState}
        placeholder={placeholder}
        id="password"
        name="password"
        required
      />
      <button className="toggle" onClick={handleChangeType}>
        <Graphic name={typeState === "password" ? "iconEyeNot" : "iconEye"} />
      </button>
    </div>
  );
}

export default Input;
